import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>tharuka.dev Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M53.37,28.32v6.09c0,0.57-0.47,1.04-1.04,1.04h-7.96c-0.57,0-1.04,0.47-1.04,1.04v26.49c0,0.57-0.47,1.04-1.04,1.04h-6.39c-0.57,0-1.04-0.47-1.04-1.04v-26.5c0-0.57-0.47-1.04-1.04-1.04h-7.85c-0.57,0-1.04-0.47-1.04-1.04v-6.09c0-0.57,0.47-1.04,1.04-1.04h26.37C52.9,27.28,53.37,27.74,53.37,28.32z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 10, 20
                  A10,10 0 0,1 20,10
                  L80,10
                  A10,10 0 0,1 90,20
                  L90,80
                  A10,10 0 0,1 80,90
                  L20,90
                  A10,10 0 0,1 10,80
                  z"
      />
    </g>
  </svg>
);

export default IconLoader;
