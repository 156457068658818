import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 96">
    <title>Logo</title>
    <circle

      fill="none"
      stroke="currentColor"
      strokeWidth="5"
    />
  </svg>
);

export default IconLogo;
